<template>
  <div id="app">
    <router-view />
    <!-- <a
      href="https://api.whatsapp.com/send?phone=6281806774516"
      class="float"
      target="_blank"
    >
      <img
        src="@/assets/img/icons/social-media/floating-whatsapp.png"
        class="floating-whatsapp"
        alt="floating-whatsapp"
      />
    </a> -->
    <Modal />
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import Modal from "./components/partials/modal/modal.vue";

export default {
  components: {
    Modal
  },
  mounted() {
    axios({
      method: "GET",
      url: `holiday`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${this.bearer_token}`,
      },
    }).then((result) => {
      const date = result.data?.data?.map((candidate) => candidate.date);
      moment.updateLocale("id", {
        workingWeekdays: [1, 2, 3, 4, 5],
        holidays: date,
        holidayFormat: "YYYY-MM-DD",
      });
    });
  },
  created() {
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    let reffCode = params.get("rf");
    let slsCode = params.get("sls");
    if (reffCode) {
      localStorage.clear();
      localStorage.setItem("refCode", reffCode);
    } else if (slsCode) {
      localStorage.clear();
      localStorage.setItem("slsCode", slsCode);
    }
  },
};
</script>

<style scoped>
.float {
  position: fixed;
  bottom: 0px;
  right: 0px;
  border-radius: 50px;
  z-index: 100;
}

img {
  width: 120px;
}

.my-float {
  margin-top: 16px;
}

@media only screen and (max-width: 768px) {
  .float {
    bottom: 48px;
    right: -24px;
  }

  img {
    width: 120px;
  }
}
</style>
