<template>
    <div>
        <b-modal id="checkModal" ref="checkModal" size="md" class="modal-failed-register" centered hide-footer
            hide-header no-close-on-backdrop>
            <div class="d-block text-center">
                <img src="@/assets/img/ekyc/verification-proccess.svg" alt="data-not-correct" />
                <h1 class="p-4 my-2 text-dark">Sesi Anda Telah Berakhir</h1>
                <p>
                    Harap login kembali untuk melanjutkan.<br />Kami akan mengarahkan Anda ke halaman login.
                </p>
                <b-row class="justify-content-md-end btn-submit-row mb-4">
                    <div class="col-lg-12 col-md-6">
                        <b-button size="sm" class="btn-tertiary px-4 py-2" @click="redirectToLogin">Login</b-button>
                    </div>
                </b-row>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "SessionExpiredModal",
    methods: {
        redirectToLogin() {
            this.$refs.checkModal.hide();
            window.location.href = "/"
        },
        openModal() {
            this.$refs.checkModal.show();
        },
    },
    mounted() {
        window.addEventListener("session-expired", this.openModal);
    },
    beforeDestroy() {
        window.removeEventListener("session-expired", this.openModal);
    },
};
</script>